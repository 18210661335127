import { getCookie } from "@/utils/cookies";
import dayjs from "@/plugins/dayjs";
import Bowser from "bowser";
import {
  ADULT_CONFIRM_APPROVED,
  ADULT_CONFIRM_MODERTION,
  ADULT_CONFIRM_REJECTED,
  MENTOR_ROLE,
  PARTICIPANT_ROLE,
} from "@/constants";

const consentStatuses = {
  [ADULT_CONFIRM_MODERTION]: "moderation",
  [ADULT_CONFIRM_APPROVED]: "accepted",
  [ADULT_CONFIRM_REJECTED]: "rejected",
};

/**
 * @returns
 */
export const getReport = ({
  release,
  user,
  track,
  selectedProfiles,
  teamProfiles,
  profiles,
  teamProfileTracks,
  tracks,
  error,
}) => {
  let bowser = undefined;

  try {
    bowser = Bowser.parse(window.navigator.userAgent);
  } catch (error) {
    // ignore
  }

  const result = {
    Сборка: release,
    "Время создания отчета": dayjs().format("D.MM.YY HH:mm Z"),
    Платформа: bowser ? bowser.platform?.type : "unknown",
    Система: bowser ? `${bowser.os?.name} ${bowser.os?.version}` : "unknown",
    Браузер: bowser
      ? `${bowser.browser?.name} ${bowser.browser?.version} (${bowser.engine?.name})`
      : "unknown",
    YM: getCookie("_ym_uid"),
  };

  if (user) {
    const role = user?.role;
    const yandex_id =
      role === MENTOR_ROLE ? user?.yandex_id : user?.participant?.yandex_id;

    const age = user?.birthday
      ? dayjs().diff(dayjs(user?.birthday, "YYYY-MM-DD"), "year")
      : undefined;
    result["Возраст"] = age;
    result.id = user.id;
    result.talent_id = user.talent_id || "Не авторизован";
    result.yandex_id = yandex_id || "Отсутствует";

    result["Роль"] =
      role === MENTOR_ROLE
        ? "Наставник"
        : role === PARTICIPANT_ROLE
        ? "Участник"
        : "Отсутствует";

    if (age < 18) {
      result["Статус файла согласия"] =
        consentStatuses[user.adult_confirmed] || "not_loaded";
    } else {
      result["Статус файла согласия"] = "Не требуется";
    }

    if (role === PARTICIPANT_ROLE) {
      result["Трек НТО"] = track?.title;
      result["Выбранные профили"] = "Отсутствуют";
      result["Класс/Курс"] = user?.participant?.grade || "Отсутствует";

      if (selectedProfiles?.length) {
        const resultProfiles = selectedProfiles
          .map((n) => {
            return profiles[n.profile_id]?.title || n.profile_id;
          })
          .filter(Boolean);
        result["Выбранные профили"] = resultProfiles.join(", ");
      }

      if (teamProfiles?.length) {
        const resultProfiles = teamProfiles
          .map((n) => {
            const title = profiles[n.profile_id]?.title;
            if (teamProfileTracks) {
              const track = teamProfileTracks[n.profile_id];
              const trackTitle = tracks[track];
              return `${title} (трек: ${trackTitle?.title || track})`;
            }
            return title;
          })
          .filter(Boolean);
        result["Профили командного этапа"] = resultProfiles.join(", ");
      }
    }
  } else {
    result["Пользователь не авторизован"] = "Да";
  }

  if (error) {
    result["Ошибка"] = error;
  }

  return result;
};
