import { JUNIOR_ALIAS, LOW_SCHOOL_ALIAS } from "@/constants";

export const subjects = [
  {
    title: "Сменить класс/трек/профиль/роль",
    for: ["participant"],
  },
  {
    title: "Не могу выбрать профиль",
    for: ["participant"],
  },
  {
    title: "Не могу привязать/сменить Яндекс Id",
    for: ["all"],
  },
  {
    title: "Задания повышенной сложности",
    for: [LOW_SCHOOL_ALIAS],
  },
  {
    title: "Вопрос по задачам олимпиады",
    for: ["all"],
  },
  {
    title: "Проблема с переходом к заданию",
    for: ["all"],
  },
  {
    title: "Как создать команду",
    for: ["participant"],
  },
  {
    title: "Как поменять капитана, удалить участника, добавить участника",
    for: ["participant"],
  },
  {
    title: "Не вижу баллов/Баллы не верные",
    for: ["all"],
  },
  {
    title: "Не вижу привилегии/Как применить привилегию",
    for: ["participant"],
  },
  {
    title: "Прохождение заданий для трека Junior",
    for: [JUNIOR_ALIAS],
  },
  {
    title: "Сменить роль",
    for: ["mentor"],
  },
  {
    title: "Не вижу своих учеников",
    for: ["mentor"],
  },
  {
    title: "Не вижу достижения участников",
    for: ["mentor"],
  },
  {
    title: "Не правильно отображается организация, как поменять организацию",
    for: ["mentor"],
  },
  {
    title: "Другое",
    for: ["all"],
  },
];

export const instructions = [
  {
    id: "preferredTrack",
    title: "Сменить класс/трек/профиль/роль",
  },
  {
    id: "teamCreate",
    title: "Как создать команду",
  },
  {
    id: "teamEdit",
    title: "Как поменять капитана, удалить участника, добавить участника",
  },
];
