import store from "@/store";
import { PARTICIPANT_ROLE, MENTOR_ROLE } from "@/constants";
export const middleware = async (to, from, next) => {
  try {
    store.commit("ui/SET_STATE", { key: "loading", value: true });
    await store.dispatch("user/getMe");
    const role = store.state.user.user?.role;
    if (role === PARTICIPANT_ROLE) {
      try {
        await store.dispatch("participant/getInfo");
        store.commit("ui/SET_LAYOUT", "user");
        try {
          const requests = [
            store.dispatch("getTracks"),
            store.dispatch("profile/getProfiles"),
            store.dispatch("getSchedule"),
          ];
          await Promise.all(requests);
        } catch (error) {
          console.log("error", error);
        }
      } catch (error) {
        store.commit("ui/SET_LAYOUT", "reg");
      }
      store.commit("ui/SET_STATE", { key: "loading", value: false });
    } else if (role === MENTOR_ROLE) {
      store.commit("ui/SET_LAYOUT", "mentor");
      try {
        store.dispatch("mentor/getMyOrganizations");
      } catch (error) {
        console.log("error", error);
      }

      store.commit("ui/SET_STATE", { key: "loading", value: false });
    }
  } catch (error) {
    store.commit("ui/SET_STATE", { key: "loading", value: false });
    store.commit("ui/SET_LAYOUT", "reg");
  }
  next();
};
